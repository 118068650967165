<template>
  <div id="locations-page" class="w-full">
    <v-breadcrumbs :items="breadcrumbList" class="pl-0 config-breadcrumbs">
      <template v-slot:item="{ item }">
        <v-breadcrumbs-item @click.native="!item.disabled && $router.push(item.href)" class="primary--text"
          :class="{'secondary--text': breadcrumbList.length > 1 && item.disabled}">
          {{ item.text }}
        </v-breadcrumbs-item>
      </template>
      <template v-slot:divider>
        <v-icon>mdi-chevron-right</v-icon>
      </template>
    </v-breadcrumbs>
    <location-form v-if="locationId" />
    <location-home v-else />
  </div>
</template>

<script>
export default {
  name: "Locations",
  metaInfo: {
    title: "Locations",
  },
  components: {
    locationForm: () => import("../locations/components/LocationForm"),
    locationHome: () => import("../locations/components/LocationHome"),
  },
  data: (vm) => ({
      locationId: vm.$route.params.locationId,
      breadcrumbList: [{
        text: 'Locations',
        disabled: true,
        href: '/locations',
      }]
  }),
  watch: {
    $route(newVal) {
      this.locationId = newVal.params.locationId;
      this.setBreadCrumb();
    }
  },
  created() {
    this.setBreadCrumb();
  },
  methods: {
    setBreadCrumb() {
      if (this.locationId) {
        this.breadcrumbList[0].disabled = false;
        this.breadcrumbList.push({
          text: `${this.locationId == 'create' ? 'Create' : 'Update'} Location`,
          disabled: true,
          href: `/locations/${this.locationId}`,
        })
      } else {
        this.breadcrumbList = [{
          text: 'Locations',
          disabled: true,
          href: '/locations',
        }]
      }
    }
  }
};
</script>
